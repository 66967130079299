import { Link } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

// styles
const pageStyles = {
  padding: "96px",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <title>Páxina non atopada</title>
      </Helmet>
      <h1 style={headingStyles}>Páxina non atopada</h1>
      <p style={paragraphStyles}>
        Desculpa{" "}
        <span role="img" aria-label="Emoticono pensativo">
          😔
        </span>{" "}
        non podemos atopar a páxina que estás a buscar.
      </p>
      <p>
        <Link to="/">Ir ao comezo</Link>
      </p>
    </main>
  )
}

export default NotFoundPage
